@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #FBF8F8;
}

@font-face {
  font-family: regularFont;
  src: url(../fonts/Metropolis-Regular.otf);
}

@font-face {
  font-family: mediumFont;
  src: url(../fonts/Metropolis-Medium.otf);
}

@font-face {
  font-family: avenirNextReg;
  src: url(../fonts/AvenirNextLTPro-Regular.otf);
}

@font-face {
  font-family: avenirNextMed;
  src: url(../fonts/avenir-next-medium.ttf);
}

@font-face {
  font-family: manrope-light;
  src: url(../fonts/Manrope-Light.ttf);
}

@font-face {
  font-family: manrope-regular;
  src: url(../fonts/Manrope-Regular.ttf);
}

@font-face {
  font-family: manrope-medium;
  src: url(../fonts/Manrope-Medium.ttf);
}

.font-400 {
  font-family: manrope-regular;
}

:root {
  font-family: manrope-light;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.steps .step:after {
  border-radius: 10px;
}

.smth {
  transform: translateX(-20%);
}

.anime1 {
  animation: hero-animation 1s ease-in-out 2.2s both;
}

.anime2 {
  animation: hero-animation 1.3s ease-in-out 3s both;
}

.anime3 {
  animation: hero-animation 1.5s ease-in-out 3.4s both;
}

.anime4 {
  animation: hero-animation 1.7s ease-in-out 5s both;
}

.anime5 {
  animation: hero-animation 3.5s ease-in-out 1.5s both;
}

@keyframes hero-animation {
  0% {
    transform: translateX(1500px);
    width: 2000px;
    opacity: 1;
  }

  50% {
    opacity: 1
  }

  100% {
    transform: translateX(-700px);
    opacity: 1;
    width: 2000px
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}