/* HERO */



.section-header {
  @apply text-[12px] leading-[26px] mx-4 pt-[10vw]
}

.project-desc-h2 {
  @apply text-[28px] mb-2 mt-2
}

.project-desc-h3 {
  @apply text-[21px] mb-2
}

.project-desc-p {
  @apply text-[16px]
}

.project-desc-li {
  @apply text-black
}

/* Footer */

.btn-circle {
  border-radius: 5px;
}

.checkbox {
  border-radius: 2px;
}

.btn-font {
  font-family: manrope-regular;
}