@function makelongshadow($length, $angle) {
  $val: 0px 0px transparent;

  @for $i from 1 through $length {
    $val: #{$val},
    #{$i}px #{$i*$angle}px #c4c4c465;
  }

  @return $val;
}

.hero-logo {
  animation: netflix_style 4.5s;
  outline: none;
  white-space: nowrap;
}


@keyframes netflix_style {
  0% {
    text-shadow: makelongshadow(5, 1);
    color: rgba(0, 0, 0, 0.11);
    transform: scale(1.3, 1.3);
  }

  10% {
    text-shadow: makelongshadow(30, 1.5);
    color: rgba(0, 0, 0, 0.342);
    transform: scale(1.3, 1.3);
  }

  15% {
    color: rgba(0, 0, 0, 0.507);
  }

  20% {
    color: rgba(0, 0, 0, 0.712);
    text-shadow: none;
    transform: scale(1.05, 1.05);
  }

  75% {
    opacity: 1;
  }

  80% {
    opacity: 1;
    color: black;
    transform: scale(1);
  }

  100% {
    opacity: 1;
  }
}